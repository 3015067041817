<template>
    <div>
            <b-row>
                <b-col cols="12">
                        <b-form-group :label="$t('faculty')">
                            <div class="label-as-input">
                                {{ formData.faculty ? getLocaleText(formData.faculty, 'name') : '-' }}
                            </div>
                        </b-form-group>
                        <b-form-group :label="$t('program')">
                            <div class="label-as-input">
                                {{ formData.program ? getLocaleText(formData.program, 'name') : '-' }}
                            </div>
                        </b-form-group>
                        <b-form-group :label="$t('semester_1')">
                            <div class="label-as-input">
                                {{ formData.semester ? formData.semester.academic_year + ' / ' + getSemester(formData.semester.semester) : '-' }}
                            </div>
                        </b-form-group>
                        <b-form-group :label="$t('semester_count')">
                            <div class="label-as-input">
                                {{ getObjectValue(formData,'semester_count') }}
                            </div>
                        </b-form-group>
                        <b-form-group :label="$t('quota')">
                            <div class="label-as-input">
                                {{ isNotNullValue(formData.quota) }}
                            </div>
                        </b-form-group>
                </b-col>
            </b-row>

    </div>
</template>

<script>
    // Services
    import MinorApplicationQuotaService from '@/services/MinorApplicationQuotaService'

    // Other

    export default {

        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formLoading: false,
                faculty_code: null,
                formData: {}
            }
        },
        async created() {
            await this.get()
        },
        methods: {
            get() {
                MinorApplicationQuotaService.get(this.formId)
                                            .then(response => {
                                                let data = response.data.data
                                                this.formData = data
                                            })
                                            .catch(() => {
                                                this.$router.push('/404')
                                            });
            }
        }
    }
</script>
