<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="faculty_code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="formData.faculty_code"
                                               :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="program_code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="formData.program_code"
                                               :faculty_code="formData.faculty_code"
                                               :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester_1')">
                            <semesters-selectbox :validate-error="errors[0]"
                                                 v-model="formData.semester_id"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="semester_count" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester_count')">
                            <period-selectbox :validate-error="errors[0]"
                                              :from-range="3"
                                              :to-range="6"
                                              v-model="formData.semester_count">
                            </period-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="quota" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('quota')">
                            <b-form-input type="number"
                                          min="0" max="1000"
                                          v-check-min-max-value
                                          v-model="formData.quota"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-5">
                <b-button variant="primary"
                          @click="updateForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Component
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
    import ProgramSelectbox from '@/components/interactive-fields/ProgramMinorSelectbox'
    import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'
    import PeriodSelectbox from "@/components/interactive-fields/PeriodSelectbox"

    // Services
    import MinorApplicationQuotaService from '@/services/MinorApplicationQuotaService'

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            FacultySelectbox,
            ProgramSelectbox,
            SemestersSelectbox,
            PeriodSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formLoading: false,
                faculty_code: null,
                formData: {
                    program_code: null,
                    faculty_code: null,
                    semester_id: null,
                    quota: null,
                    point_type: null
                }
            }
        },
        async created() {
            await this.get()
        },
        methods: {
            get() {
                MinorApplicationQuotaService.get(this.formId)
                                            .then(response => {
                                                let data = response.data.data
                                                this.faculty_code = data.program.faculty_code
                                                this.formData = {
                                                    faculty_code: data.program.faculty_code,
                                                    program_code: data.program_code,
                                                    semester_id: data.semester_id,
                                                    quota: data.quota,
                                                    semester_count:data.semester_count
                                                }
                                            })
                                            .catch((e) => {
                                                this.$router.push('/404')
                                            });
            },

            async updateForm() {
                if(this.checkPermission('minorapplicationquota_update')){
                    const isValid = await this.$refs.formModalValidate.validate();
                    if (isValid && this.formId) {
                        this.formLoading = true;
                        MinorApplicationQuotaService.update(this.formId, this.formData)
                                                    .then(response => {
                                                        this.$emit('updateFormSuccess', true);
                                                        this.$toast.success(this.$t('api.' + response.data.message));
                                                    })
                                                    .catch(e => {
                                                        this.showErrors(e, this.$refs.formModalValidate);
                                                    })
                                                    .finally(() => {
                                                        this.formLoading = false
                                                    })
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }

            }
        }

    }
</script>
