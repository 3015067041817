<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="faculty_code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="formData.faculty_code"
                                               :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="program_code" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="formData.program_code"
                                               :faculty_code="formData.faculty_code"
                                               :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester_1')">
                            <semesters-selectbox :validate-error="errors[0]"
                                                 v-model="formData.semester_id">
                            </semesters-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="semester_count" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('semester_count')">
                            <period-selectbox :validate-error="errors[0]"
                                              :fromRange="3"
                                              :toRange="6"
                                              v-model="formData.semester_count">
                            </period-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="quota" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('quota')">
                            <b-form-input type="number"
                                          min="0" max="100"
                                          v-check-min-max-value
                                          v-model="formData.quota"
                                          :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-5">
                <b-button variant="primary"
                          @click="createForm"
                          :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Component
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
    import ProgramSelectbox from '@/components/interactive-fields/ProgramMinorSelectbox'
    import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'
    import PeriodSelectbox from '@/components/interactive-fields/PeriodSelectbox'

    // Services
    import MinorApplicationQuotaService from '@/services/MinorApplicationQuotaService';
    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            FacultySelectbox,
            ProgramSelectbox,
            SemestersSelectbox,
            PeriodSelectbox,

            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                formLoading: false,
                faculty_code: null,
                formData: {
                    program_code: null,
                    semester_id: null,
                    quota: null,
                    point_type: null
                }
            }
        },
        mounted() {
            setTimeout(() => {
                this.$refs.formModalValidate.reset();
            }, 2000)
        },
        methods: {
            async createForm() {
                if(this.checkPermission('minorapplicationquota_store')){
                    const isValid = await this.$refs.formModalValidate.validate();
                    if (isValid) {
                        this.formLoading = true;
                        MinorApplicationQuotaService.store(this.formData)
                                                    .then(response => {
                                                        this.$emit('createFormSuccess', true);
                                                        this.$toast.success(this.$t('api.' + response.data.message));
                                                        this.$refs.formModalValidate.reset();
                                                    })
                                                    .catch(e => {
                                                        this.showErrors(e, this.$refs.formModalValidate);
                                                    })
                                                    .finally(() => {
                                                        this.formLoading = false
                                                    })
                    }
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            }
        }
    }
</script>
